import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HTTP_INTERCEPTORS,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { JWT_TOKEN } from 'src/app/core/const/constants'

const TOKEN_HEADER_KEY = 'Authorization'
const TENANT_HEADER_KEY = 'tenant'
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private _tenant = ''
    constructor(private cookieService: CookieService, private router: Router) {
        const domain = window.location.host
        this._tenant = domain.split(".")[0]
    }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let authRequ = req
        const token = this.cookieService.get(JWT_TOKEN)
        authRequ = req.clone({
            headers: req.headers
                .set(TOKEN_HEADER_KEY, 'Bearer ' + token)
                //.set(TENANT_HEADER_KEY, this._tenant),
                .set(TENANT_HEADER_KEY, 'divinaprovidencia'),
        })
        return next.handle(authRequ).pipe(
            tap({
                next: (x) => {},
                error: (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status !== 401) {
                            return
                        }
                        this.router.navigate(['login'])
                    }
                },
            })
        )
    }
}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
]
