import { Component, Inject, OnInit } from '@angular/core'
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ILocation } from 'src/app/core/models/location'

@Component({
    selector: 'app-add-location',
    templateUrl: './add-location.component.html',
    styleUrls: ['./add-location.component.scss'],
})
export class AddLocationComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AddLocationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ILocation
    ) {}
    isEdit = false
    formGroup!: UntypedFormGroup
    title = 'Nueva'
    formControl = new UntypedFormControl('', [Validators.required])

    ngOnInit(): void {
        if (this.data.name != null) {
            this.title = 'Editar'
            this.isEdit = true
        }
        console.log(this.data)
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(this.data?.name, Validators.required),
        })
    }

    getErrorMessage() {
        return this.formControl.hasError('required') ? 'Campo requerido' : ''
    }

    onNoClick(): void {
        this.dialogRef.close()
    }

    submit() {
        console.log('submit')
    }
    public confirmAdd(): void {
        console.log(this.formGroup.value)
        this.dialogRef.close(this.formGroup.value)
    }
}
