import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ICategory } from 'src/app/core/models/Category'
import { CategoriesService } from 'src/app/core/services/api/categories.service'

@Component({
    selector: 'app-add-subcategory',
    templateUrl: './add-subcategory.component.html',
    styleUrls: ['./add-subcategory.component.scss'],
})
export class AddSubcategoryComponent implements OnInit {
    private _categories: ICategory[] = []
    get categories() {
        return this._categories
    }

    constructor(
        public dialogRef: MatDialogRef<AddSubcategoryComponent>,
        private changeDetRef: ChangeDetectorRef,
        private categoriesService: CategoriesService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    formGroup!: UntypedFormGroup
    title = 'Nueva'
    formControl = new UntypedFormControl('', [Validators.required])

    ngOnInit(): void {
        if (this.data.name != null) {
            this.title = 'Editar'
        }
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(this.data?.name, Validators.required),
            categoryId: new UntypedFormControl(
                this.data?.categoryId,
                Validators.required
            ),
        })

        this.categoriesService.categories.subscribe((categories) => {
            this._categories = categories
        })
    }

    getErrorMessage() {
        return this.formControl.hasError('required') ? 'Campo requerido' : ''
    }

    onNoClick(): void {
        this.dialogRef.close()
    }

    public confirmAdd(): void {
        console.log(this.formGroup.value)
        this.dialogRef.close(this.formGroup.value)
    }
}
