<nav
    id="sidebar"
    class="sidenav"
    [ngClass]="{ hidden: sideNavService.hideSideNav }"
>
    <div class="welcome" [ngClass]="{ collapse: sideNavService.hideSideNav }">
        <img src="./assets/img/logo.png" />
        <p>¡Bienvenid@</p>
    </div>
    <ul class="navbar-nav">
        <li
            *ngIf="isAdmin"
            class="nav-item"
            [ngClass]="{ active: router.url === '/admin/categories' }"
            (click)="navigateTo('admin/categories')"
        >
            <mat-icon class="material-icons-outlined">category</mat-icon>
            <a class="nav-link">Categorias</a>
        </li>
        <li
            *ngIf="isAdmin"
            class="nav-item"
            [ngClass]="{ active: router.url === '/admin/subcategories' }"
            (click)="navigateTo('admin/subcategories')"
        >
            <mat-icon class="material-icons-outlined">category</mat-icon>
            <a class="nav-link">Subcategorias</a>
        </li>
        <li
            *ngIf="isAdmin"
            class="nav-item"
            [ngClass]="{ active: router.url === '/admin/locations' }"
            (click)="navigateTo('admin/locations')"
        >
            <mat-icon class="material-icons-outlined">storefront</mat-icon>
            <a class="nav-link">Ubicaciones</a>
        </li>
        <!--<li
            class="nav-item"
            [ngClass]="{ active: router.url === '/' }"
            (click)="navigateTo('')"
        >
            <mat-icon class="material-icons-outlined">home</mat-icon>
            <a class="nav-link">Inicio</a>
        </li>-->
        <li
            class="nav-item"
            [ngClass]="{ active: router.url === '/ingresos' }"
            (click)="navigateTo('ingresos')"
        >
            <mat-icon class="material-icons-outlined">price_check</mat-icon>
            <a class="nav-link">Ingresos</a>
        </li>
        <li
            class="nav-item"
            [ngClass]="{ active: router.url === '/egresos' }"
            (click)="navigateTo('egresos')"
        >
            <mat-icon class="material-icons-outlined">paid</mat-icon>
            <a class="nav-link">Egresos</a>
        </li>
        <li
            class="nav-item"
            [ngClass]="{ active: router.url === '/cantidad-entregada' }"
            (click)="navigateTo('cantidad-entregada')"
        >
            <mat-icon class="material-icons-outlined">savings</mat-icon>
            <a class="nav-link">Cantidad Entregada</a>
        </li>
        <li>
            <mat-expansion-panel class="my-menu-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>show_chart</mat-icon>
                        Reportes
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ul>
                    <li
                        class="nav-item"
                        [ngClass]="{ active: router.url === '/corte-diario' }"
                        (click)="navigateTo('corte-diario')"
                    >
                        <a class="nav-link">Corte diario</a>
                    </li>
                    <li
                        class="nav-item"
                        [ngClass]="{
                            active: router.url === '/flujo-efectivo-simple'
                        }"
                        (click)="navigateTo('flujo-efectivo-simple')"
                    >
                        <a class="nav-link">Flujo de efectivo</a>
                    </li>
                    <li
                        class="nav-item"
                        [ngClass]="{ active: router.url === '/flujo-efectivo' }"
                        (click)="navigateTo('flujo-efectivo')"
                    >
                        <a class="nav-link">Ingreso / Egreso por dia</a>
                    </li>
                    <!--<li
                        class="nav-item"
                        [ngClass]="{
                            active: router.url === '/historico-servicios'
                        }"
                        (click)="navigateTo('historico-servicios')"
                    >
                        <a class="nav-link">Historico Servicios</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Historico Ingresos</a>
                    </li>-->
                    <li
                        class="nav-item"
                        [ngClass]="{
                            active: router.url === '/reporte-mensual'
                        }"
                        (click)="navigateTo('reporte-mensual')"
                    >
                        <a class="nav-link">Reporte mensual</a>
                    </li>
                    <!--<li class="nav-item">
                        <a class="nav-link">Comparativo Ingreso / Egresos</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Misas</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Gastos parroquia</a>
                    </li>-->
                </ul>
            </mat-expansion-panel>
        </li>
    </ul>
</nav>
