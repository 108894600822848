import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { JWT_TOKEN } from 'src/app/core/const/constants'
import { SessionManagementService } from 'src/app/core/services/util/session-management.service'

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    user = ''
    constructor(private cookieService: CookieService, private router: Router) {}

    ngOnInit() {
        let storedUser = sessionStorage.getItem('user')
        this.user = storedUser != null ? storedUser : 'e-cclesia'
    }

    closeSession() {
        sessionStorage.clear()
        this.cookieService.delete(JWT_TOKEN)
        this.router.navigateByUrl('login')
    }
}
