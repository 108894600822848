import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
} from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ICategory } from 'src/app/core/models/Category'
import { ISubcategory } from 'src/app/core/models/subcategory'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { AddSubcategoryComponent } from '../add-subcategory/add-subcategory.component'
import { ILocation } from 'src/app/core/models/location'
import { LocationsService } from 'src/app/core/services/api/locations.service'

@Component({
    selector: 'app-add-record',
    templateUrl: './add-record.component.html',
    styleUrls: ['./add-record.component.scss'],
})
export class AddRecordComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AddSubcategoryComponent>,
        private categoryService: CategoriesService,
        private locationsService: LocationsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    private _subcategories: ISubcategory[] = []
    private _locations: ILocation[] = []
    private categories: ICategory[] = []
    formGroup!: UntypedFormGroup
    title = 'Nuevo'
    formControl = new UntypedFormControl('', [Validators.required])
    get subcategories() {
        return this._subcategories
    }
    get locations(){
        return this._locations
    }
    ngOnInit(): void {
        if (this.data.title != null) {
            this.title = this.data.title
        }
        if (this.data.categories != null) {
            this.data.categories = (this.data.categories as ICategory[]).sort(
                (a, b) => a.name.localeCompare(b.name)
            )
            //this.data.categories = this.data.categories.sort
        }
        this.formGroup = new UntypedFormGroup({
            ticketNo: new UntypedFormControl(
                this.data?.record?.ticketNo,
                Validators.required
            ),
            categoryId: new UntypedFormControl(
                this.data?.record?.categoryId,
                Validators.required
            ),
            recordTypeId: new UntypedFormControl(this.data?.recordTypeId),
            subcategoryId: new UntypedFormControl(
                this.data?.record?.subcategoryId,
                Validators.required
            ),
            payee: new UntypedFormControl(
                this.data?.record?.payee,
                Validators.required
            ),
            amount: new UntypedFormControl(
                this.data?.record?.amount,
                Validators.required
            ),
            notes: new UntypedFormControl(
                this.data?.record?.notes,
                Validators.required
            ),
            locationId: new UntypedFormControl(
                this.data?.record?.locationId
            ),
            dateCreated: new UntypedFormControl(new Date()),
        })
        this.locationsService.get().subscribe(locations=>{
            this._locations = locations
        })
    }

    getErrorMessage() {
        return this.formControl.hasError('required') ? 'Campo requerido' : ''
    }

    categorySelected(id: any) {
        this.categoryService.getById(id).subscribe((category) => {
            this._subcategories = category.subcategories.sort((a, b) =>
                a.name.localeCompare(b.name)
            )
        })
    }

    onNoClick(): void {
        this.dialogRef.close()
    }

    submit() {
        console.log('submit')
    }
    public confirmAdd(): void {
        console.log(this.formGroup.value)
        this.dialogRef.close(this.formGroup.value)
    }
}
