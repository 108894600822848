<mat-card class="no-media">
    <div class="form form-top">
        <h2>Filtros</h2>
        <div class="filtros">
            <mat-form-field appearance="fill">
                <mat-label>Month and Year</mat-label>
                <input
                    matInput
                    [max]="maxDate"
                    [matDatepicker]="dp"
                    [formControl]="date"
                />
                <mat-hint>MM/YYYY</mat-hint>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="dp"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #dp
                    startView="multi-year"
                    (monthSelected)="setMonthAndYear($event, dp)"
                    panelClass="example-month-picker"
                >
                </mat-datepicker>
            </mat-form-field>
            <mat-form-field color="accent" class="title">
                <input
                    matInput
                    class="form-control"
                    placeholder="Titulo del Reporte"
                    [(ngModel)]="dateString"
                />
            </mat-form-field>
        </div>
        <button class="print_button" mat-raised-button (click)="print()">
            <mat-icon>print</mat-icon>Imprimir
        </button>
    </div>
</mat-card>
<h2 class="media-only">{{ dateString }}</h2>
<div class="tables-container">
    <div *ngFor="let data of dataSources" class="day mat-elevation-z8 page">
        <h2>{{ data.date | date: 'dd/MM/YYYY' }}</h2>
        <div class="row">
            <div class="col-md-6 container table3">
                <h3>Ingresos</h3>
                <table mat-table [dataSource]="data.income">
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>Nombre</th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="element.style"
                        >
                            {{ element.title }}
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="count">
                        <th class="quantity" mat-header-cell *matHeaderCellDef>
                            Cantidad
                        </th>
                        <td
                            class="quantity"
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="element.style"
                        >
                            {{ element.count }}
                        </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="total">
                        <th class="quantity" mat-header-cell *matHeaderCellDef>
                            Total
                        </th>
                        <td
                            class="quantity"
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="element.style"
                        >
                            {{ element.total | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>
            </div>
            <div class="col-md-6 container table2">
                <h3>Egresos</h3>
                <table mat-table [dataSource]="data.outcome">
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>Nombre</th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="element.style"
                        >
                            {{ element.title }}
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="count">
                        <th class="quantity" mat-header-cell *matHeaderCellDef>
                            Cantidad
                        </th>
                        <td
                            class="quantity"
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="element.style"
                        >
                            {{ element.count }}
                        </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="total">
                        <th class="quantity" mat-header-cell *matHeaderCellDef>
                            Total
                        </th>
                        <td
                            class="quantity"
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="element.style"
                        >
                            {{ element.total | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-card class="total">
                    <h5>
                        Total ingresos:
                        <label>{{ data.total_income | currency }}</label>
                    </h5>
                </mat-card>
            </div>
            <div class="col-md-4">
                <mat-card class="total">
                    <h5>
                        Total egresos:
                        <label>{{ data.total_outcome | currency }}</label>
                    </h5>
                </mat-card>
            </div>
            <div class="col-md-4">
                <mat-card class="total">
                    <h5>
                        Saldo: <label>{{ data.balance | currency }}</label>
                    </h5>
                </mat-card>
            </div>
        </div>
    </div>
</div>
