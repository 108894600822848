import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { ICategory } from 'src/app/core/models/Category'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { SubcategoriesService } from 'src/app/core/services/api/subcategories.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { AddDialogComponent } from '../../components/dialogs/add-dialog/add-dialog.component'
import { AddSubcategoryComponent } from '../../components/dialogs/add-subcategory/add-subcategory.component'
import {
    ConfirmationComponent,
    ConfirmationData,
} from '../../components/dialogs/confirmation/confirmation.component'

@Component({
    selector: 'app-subcategories',
    templateUrl: './subcategories.component.html',
    styleUrls: ['./subcategories.component.scss'],
})
export class SubcategoriesComponent implements OnInit, AfterViewInit {
    displayedColumns = ['name', 'category', 'actions']
    dataSource = new MatTableDataSource()
    private _categories: ICategory[] = []
    private _filter: string = ''
    get filter() {
        return this._filter
    }
    set filter(filter: string) {
        this._filter = filter
    }
    get categories() {
        return this._categories
    }
    getCategoryName(id: number) {
        return this._categories.find((cat) => cat.categoryId === id)?.name
    }
    constructor(
        private subcategoriesService: SubcategoriesService,
        private categoryService: CategoriesService,
        private loadingService: LoadingService,
        public dialog: MatDialog
    ) {}

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
    ngOnInit(): void {
        this.refresh()
        this.loadData()
        this.categoryService.categories.subscribe((categories) => {
            this._categories = categories
        })
    }
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator
    }
    loadData() {
        this.subcategoriesService.subcategories.subscribe((result) => {
            this.dataSource.data = result
        })
    }
    refresh() {
        this.subcategoriesService.updateData()
        this.categoryService.updateData()
        this.loadData()
        this.paginator._changePageSize(this.paginator.pageSize)
    }

    addNew() {
        const dialogRef = this.dialog.open(AddSubcategoryComponent, {
            data: { categories: this.categories },
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                console.log(result)
                this.loadingService.show()
                this.subcategoriesService.post(result).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
    applyFilter() {
        const filterValue = this._filter
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
    startEdit(id: number, name: string, categoryId: number) {
        const dialogRef = this.dialog.open(AddSubcategoryComponent, {
            data: {
                name: name,
                categoryId: categoryId,
                categories: this.categories,
            },
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                this.subcategoriesService
                    .put(result, id)
                    .subscribe((response) => {
                        this.loadingService.hide()
                        this.refresh()
                    })
            }
        })
    }
    deleteItem(id: number) {
        const data = new ConfirmationData()
        data.description = 'Estas seguro que deseas eliminar la subcategoria?'
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                console.log(id)
                this.subcategoriesService.delete(id).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
}
