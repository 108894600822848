import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import { PROD_API_URL } from '../../const/constants'
import { ICategory } from '../../models/Category'

@Injectable({
    providedIn: 'root',
})
export class CategoriesService {
    private _categories: ICategory[] = []
    private _categories_subject = new Subject<ICategory[]>()
    get categories() {
        return this._categories_subject.asObservable()
    }
    private _categories_income = new Subject<ICategory[]>()
    get categories_income() {
        return this._categories_income
    }
    private _categories_expenses = new Subject<ICategory[]>()
    get categories_expenses() {
        return this._categories_expenses
    }
    constructor(private httpService: HttpClient) {
        this.updateData()
    }

    get(): Observable<ICategory[]> {
        return this.httpService.get<ICategory[]>(PROD_API_URL + '/api/category')
    }
    getById(id: number): Observable<ICategory> {
        return this.httpService.get<ICategory>(
            PROD_API_URL + '/api/category/' + id
        )
    }
    post(body: UntypedFormGroup): Observable<ICategory[]> {
        return this.httpService.post<ICategory[]>(
            PROD_API_URL + '/api/category',
            body
        )
    }

    put(body: UntypedFormGroup, id: number): Observable<ICategory[]> {
        return this.httpService.put<ICategory[]>(
            PROD_API_URL + '/api/category/' + id,
            body
        )
    }
    delete(id: number): Observable<ICategory[]> {
        return this.httpService.delete<ICategory[]>(
            PROD_API_URL + '/api/category/' + id
        )
    }

    updateData() {
        this.get().subscribe((categories) => {
            this._categories = categories
            this._categories_subject.next(categories)
            this.filterRecords()
        })
    }
    filterRecords() {
        this._categories_income.next(
            this._categories.filter(
                (categories) => categories.recordTypeId === 1
            )
        )
        this._categories_expenses.next(
            this._categories.filter(
                (categories) => categories.recordTypeId === 2
            )
        )
    }
}
