<div class="container">
    <h3 mat-dialog-title>Cantidad Entregada</h3>

    <form
        class="mat-dialog-content"
        #formControl="ngForm"
        [formGroup]="formGroup"
    >
        <!--Contains mat-hint for characters count and has maxLengt set-->
        <div class="form" [formGroup]="formGroup">
            <mat-form-field color="accent">
                <input
                    matInput
                    #inputstate
                    class="form-control"
                    placeholder="Cantidad"
                    formControlName="Amount"
                />
                <mat-error *ngIf="formGroup.invalid">{{
                    getErrorMessage()
                }}</mat-error>
            </mat-form-field>
            <br />
            <mat-form-field color="accent">
                <input
                    matInput
                    #inputstate
                    class="form-control"
                    placeholder="Notas"
                    formControlName="Notes"
                />
            </mat-form-field>
        </div>

        <div mat-dialog-actions>
            <button
                mat-button
                [disabled]="!formGroup.valid"
                [mat-dialog-close]="1"
                (click)="confirmAdd()"
            >
                Guardar
            </button>
            <button
                type="button"
                mat-button
                (click)="onNoClick()"
                tabindex="-1"
            >
                Cancelar
            </button>
        </div>
    </form>
</div>
