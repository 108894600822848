<div class="container table1 mat-elevation-z8 page">
    <div class="form form-top">
        <h2>Filtros</h2>
        <mat-form-field floatPlaceholder="never" color="accent">
            <input
                matInput
                [(ngModel)]="filter"
                (keyup)="applyFilter()"
                #input
                placeholder="Filtro"
            />
        </mat-form-field>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort class="mat-cell">
        ng update @angular/cli @angular/core
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Id</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.categoryId }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Nombre</mat-header-cell
            >
            <mat-cell *matCellDef="let row"> {{ row.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Categoria</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
                {{ getCategoryName(row.categoryId) }}</mat-cell
            >
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions" style="max-width: 100px">
            <mat-header-cell *matHeaderCellDef>
                <div class="actions-header">
                    <span>Acciones</span>
                    <mat-icon
                        aria-label="Example icon-button with a heart icon"
                        (click)="addNew()"
                        >add</mat-icon
                    >
                </div>
            </mat-header-cell>

            <mat-cell
                class="icons-actions"
                *matCellDef="let row; let i = index"
            >
                <button
                    mat-icon-button
                    color="accent"
                    (click)="
                        startEdit(row.subcategoryId, row.name, row.categoryId)
                    "
                >
                    <mat-icon aria-label="Edit">edit</mat-icon>
                </button>

                <button
                    mat-icon-button
                    color="accent"
                    (click)="deleteItem(row.subcategoryId)"
                >
                    <mat-icon aria-label="Delete">delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <div
        class="no-results"
        [style.display]="dataSource.data.length === 0 ? '' : 'none'"
    >
        No results
    </div>

    <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
    >
    </mat-paginator>
</div>
