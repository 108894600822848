import { Component, OnInit, OnDestroy, HostListener } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RECEIPT_DATA } from 'src/app/core/const/constants'
import { IReceipt } from 'src/app/core/models/receipt'

@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit, OnDestroy {
    private _data: IReceipt | undefined
    private _storageKey = ''
    get data() {
        return this._data
    }
    constructor(private route: ActivatedRoute) {}
    ngOnDestroy(): void {
        console.log('destroy')
    }
    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event: any) {
        event.preventDefault()
        localStorage.removeItem(this._storageKey)
        return false
    }

    ngOnInit() {
        console.log('init')
        this.route.queryParams.subscribe((params) => {
            const ticket = params['ticket']
            this._storageKey = `${RECEIPT_DATA}${ticket}`
            let receiptData = JSON.parse(
                localStorage.getItem(this._storageKey) ?? ''
            )
            this._data = receiptData
            window.print()
        })
    }
    print() {
        window.print()
    }
}
