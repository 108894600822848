import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { ILocation } from 'src/app/core/models/location'
import { LocationsService } from 'src/app/core/services/api/locations.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { AddLocationComponent } from '../../components/dialogs/add-location/add-location.component'
import {
    ConfirmationComponent,
    ConfirmationData,
} from '../../components/dialogs/confirmation/confirmation.component'

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit, AfterViewInit {
    displayedColumns = ['name', 'actions']
    dataSource = new MatTableDataSource()
    private _locations: ILocation[] = []
    private _filter: string = ''
    get filter() {
        return this._filter
    }
    set filter(filter: string) {
        this._filter = filter
    }

    constructor(
        private locationsService: LocationsService,
        private loadingService: LoadingService,
        public dialog: MatDialog
    ) {}

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
    ngOnInit(): void {
        this.loadData()
    }
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator
    }
    loadData() {
        this.locationsService.get().subscribe((result) => {
            console.log('data', result)
            this._locations = result
            this.dataSource.data = result
        })
    }

    addNew() {
        const dialogRef = this.dialog.open(AddLocationComponent, {
            data: {},
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result)
                this.loadingService.show()
                this.locationsService.post(result).subscribe((response) => {
                    console.log(response)
                    this.loadingService.hide()
                    this._locations.push(response)
                    this.dataSource.data = this._locations
                })
            }
        })
    }
    startEdit(index: number, id: number) {
        const dialogRef = this.dialog.open(AddLocationComponent, {
            data: this._locations[index],
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                this.locationsService.put(id, result).subscribe((response) => {
                    this.loadingService.hide()
                    this._locations[index] = result
                    this.dataSource.data = this._locations
                })
            }
        })
    }
    deleteItem(id: number, index: number) {
        const data = new ConfirmationData()
        data.description = 'Estas seguro que deseas eliminar la sucursal?'

        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                console.log(id)
                this.locationsService.delete(id).subscribe((response) => {
                    this.loadingService.hide()
                    this._locations.splice(index, 1)
                    this.dataSource.data = this._locations
                })
            }
        })
    }
}
