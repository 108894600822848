import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { RecordTypes } from 'src/app/core/const/constants'
import { ICategory } from 'src/app/core/models/Category'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { AddDialogComponent } from '../../components/dialogs/add-dialog/add-dialog.component'
import {
    ConfirmationComponent,
    ConfirmationData,
} from '../../components/dialogs/confirmation/confirmation.component'

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, AfterViewInit {
    displayedColumns = ['name', 'type', 'actions']
    dataSource = new MatTableDataSource()
    private _categories: ICategory[] = []
    private _filter: string = ''
    get filter() {
        return this._filter
    }
    set filter(filter: string) {
        this._filter = filter
    }

    constructor(
        private categoriesService: CategoriesService,
        private loadingService: LoadingService,
        public dialog: MatDialog,
        private changeDetRef: ChangeDetectorRef
    ) {}

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
    ngOnInit(): void {
        this.refresh()
    }
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator
    }
    loadData() {
        this.categoriesService.categories.subscribe((result) => {
            console.log('data', result)
            this._categories = result
            this.dataSource.data = result
            this.changeDetRef.detectChanges()
        })
    }
    getRecordType(id: number) {
        return RecordTypes[id]
    }
    refresh() {
        this.categoriesService.updateData()
        this.loadData()
        this.paginator._changePageSize(this.paginator.pageSize)
    }

    addNew() {
        const dialogRef = this.dialog.open(AddDialogComponent, {
            data: {},
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                this.categoriesService.post(result).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
    applyFilter() {
        const filterValue = this._filter
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
    startEdit(index: number, id: number) {
        const dialogRef = this.dialog.open(AddDialogComponent, {
            data: this._categories[index],
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                this.categoriesService.put(result, id).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
    deleteItem(id: number) {
        const data = new ConfirmationData()
        data.description = 'Estas seguro que deseas eliminar la categoria?'
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                console.log(id)
                this.categoriesService.delete(id).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
}
