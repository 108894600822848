import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { PROD_API_URL } from '../../const/constants'
import { IGeneralBalance } from '../../models/general-balance'

@Injectable({
    providedIn: 'root',
})
export class GeneralBalanceService {
    private _dailyBalance = new Subject<IGeneralBalance[]>()
    get dailyBalance() {
        return this._dailyBalance.asObservable()
    }

    constructor(private httpService: HttpClient) {}

    get(startDate: string, endDate: string): Observable<IGeneralBalance> {
        return this.httpService.get<IGeneralBalance>(
            PROD_API_URL +
                '/api/reports/BalanceGeneral?startDate=' +
                startDate +
                '&endDate=' +
                endDate
        )
    }

    getHistoric(year: number): Observable<string> {
        return this.httpService.get<string>(
            `${PROD_API_URL}/api/reports/Flujo?year=${year}`
        )
    }
}
