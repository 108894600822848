import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { PROD_API_URL } from '../../const/constants'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private httpService: HttpClient) {}

    login(user: UntypedFormGroup): Observable<any[]> {
        return this.httpService.post<any[]>(
            PROD_API_URL + '/api/authentication/login',
            user
        )
    }
}
