import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core'
import { LoadingService } from './core/services/util/loading.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'ecclesia-frontend'
    private _loading = false
    private _date = new Date()
    get loading() {
        return this._loading
    }
    get date() {
        return this._date
    }

    constructor(
        private loadingService: LoadingService,
        private changeDetRef: ChangeDetectorRef
    ) {}
    ngOnInit(): void {
        this.loadingService.loading.subscribe((loading) => {
            this._loading = loading
            this.changeDetRef.detectChanges()
        })
    }
}
