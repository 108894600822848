<h2 class="media-only">{{ dateString }}</h2>
<mat-card class="no-media">
    <div class="form form-top">
        <h2>Filtros</h2>
        <div class="filtros">
            <mat-form-field appearance="fill">
                <mat-label>Month and Year</mat-label>
                <input
                    matInput
                    [max]="maxDate"
                    [matDatepicker]="dp"
                    [formControl]="date"
                />
                <mat-hint>MM/YYYY</mat-hint>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="dp"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #dp
                    startView="multi-year"
                    (monthSelected)="setMonthAndYear($event, dp)"
                    panelClass="example-month-picker"
                >
                </mat-datepicker>
            </mat-form-field>
            <mat-form-field color="accent" class="title">
                <input
                    matInput
                    class="form-control"
                    placeholder="Titulo del Reporte"
                    [(ngModel)]="dateString"
                />
            </mat-form-field>
        </div>
        <button class="print_button" mat-raised-button (click)="print()">
            <mat-icon>print</mat-icon>Imprimir
        </button>
    </div>
</mat-card>
<div class="tables-container">
    <div class="mat-elevation-z8 container table3">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Fecha</th>
                <td mat-cell *matCellDef="let element" [class]="element.style">
                    {{ element.date | date: 'dd/MM/yyyy' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="income">
                <th mat-header-cell *matHeaderCellDef>Ingresos</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class]="element.style"
                    class="quantity"
                >
                    <label
                        ><span>$</span
                        >{{ element.income | currency: '':'' }}</label
                    >
                </td>
            </ng-container>
            <ng-container matColumnDef="outcome">
                <th mat-header-cell *matHeaderCellDef>Egresos</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class]="element.style"
                    class="quantity"
                >
                    <label
                        ><span>$</span
                        >{{ element.outcome | currency: '':'' }}</label
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef>Total</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class]="element.style"
                    class="quantity"
                >
                    <label
                        ><span>$</span
                        >{{ element.total | currency: '':'' }}</label
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="petty">
                <th mat-header-cell *matHeaderCellDef>Cantidad Entregada</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class]="element.style"
                    class="quantity"
                >
                    <label
                        ><span>$</span
                        >{{ element.petty | currency: '':'' }}</label
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef>Saldo</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class]="element.style"
                    class="quantity"
                >
                    <label
                        ><span>$</span
                        >{{ element.balance | currency: '':'' }}</label
                    >
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>
