<div class="container">
    <h3 mat-dialog-title>{{ title }} subcategoria</h3>

    <form
        class="mat-dialog-content"
        #formControl="ngForm"
        [formGroup]="formGroup"
    >
        <!--Contains mat-hint for characters count and has maxLengt set-->
        <div class="form" [formGroup]="formGroup">
            <mat-form-field color="accent">
                <input
                    matInput
                    #inputstate
                    class="form-control"
                    placeholder="Nombre"
                    formControlName="name"
                />
                <mat-error *ngIf="formGroup.invalid">{{
                    getErrorMessage()
                }}</mat-error>
            </mat-form-field>
            <br />
            <mat-form-field color="accent">
                <mat-label>Categoria</mat-label>
                <mat-select class="form-control" formControlName="categoryId">
                    <mat-option
                        *ngFor="let category of data.categories"
                        [value]="category.categoryId"
                    >
                        {{ category.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div mat-dialog-actions>
            <button
                mat-button
                [disabled]="!formGroup.valid"
                [mat-dialog-close]="1"
                (click)="confirmAdd()"
            >
                Guardar
            </button>
            <button
                type="button"
                mat-button
                (click)="onNoClick()"
                tabindex="-1"
            >
                Cancelar
            </button>
        </div>
    </form>
</div>
