import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import { PROD_API_URL } from '../../const/constants'
import { IRecord } from '../../models/record'
import { ISubcategory } from '../../models/subcategory'

@Injectable({
    providedIn: 'root',
})
export class RecordsService {
    private _income = new Subject<IRecord[]>()
    get income() {
        return this._income.asObservable()
    }

    private _expenses = new Subject<IRecord[]>()
    get expenses() {
        return this._expenses.asObservable()
    }
    private _records: IRecord[] = []
    constructor(private httpService: HttpClient) {
        this.updateData()
    }
    get(): Observable<IRecord[]> {
        return this.httpService.get<IRecord[]>(PROD_API_URL + '/api/record')
    }
    post(body: UntypedFormGroup): Observable<IRecord[]> {
        return this.httpService.post<IRecord[]>(
            PROD_API_URL + '/api/record',
            body
        )
    }

    put(body: UntypedFormGroup, id: number): Observable<IRecord[]> {
        return this.httpService.put<IRecord[]>(
            PROD_API_URL + '/api/record/' + id,
            body
        )
    }
    delete(id: number): Observable<IRecord[]> {
        return this.httpService.delete<IRecord[]>(
            PROD_API_URL + '/api/record/' + id
        )
    }
    filterRecords() {
        this._income.next(
            this._records.filter((record) => record.recordTypeId === 1)
        )
        this._expenses.next(
            this._records.filter((record) => record.recordTypeId === 2)
        )
    }

    updateData() {
        this.get().subscribe((records) => {
            this._records = records
            this.filterRecords()
        })
    }
}
