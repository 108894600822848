<div class="container">
    <h3 mat-dialog-title>{{ data.title }}</h3>

    <div class="mat-dialog-content">
        <!--Contains mat-hint for characters count and has maxLengt set-->
        <div class="div">
            <p>{{ data.description }}</p>
        </div>

        <div class="mat-dialog-actions">
            <button class="mat-button" (click)="onConfirm()">
                {{ data.okBtn }}
            </button>
            <button class="mat-button" (click)="onDismiss()">
                {{ data.cancelBtn }}
            </button>
        </div>
    </div>
</div>
