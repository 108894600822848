<mat-card>
    <div class="form form-top">
        <mat-form-field>
            <mat-label>Rango de fechas</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                    matStartDate
                    formControlName="start"
                    placeholder="Fecha Inicial"
                    (dateChange)="customFilter()"
                />
                <input
                    matEndDate
                    formControlName="end"
                    placeholder="Fecha Final"
                    (dateChange)="customFilter()"
                />
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <!--<mat-error *ngIf="range.controls['start']hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls['end']hasError('matEndDateInvalid')">Invalid end date</mat-error>-->
        </mat-form-field>
        <button (click)="resetRange()" class="btn-clear" mat-raised-button>
            Limpiar fechas
        </button>
    </div>
</mat-card>
