import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmationComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationComponent>,
        private changeDetRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationData
    ) {}

    onConfirm(): void {
        console.log('confirm')
        this.dialogRef.close(true)
    }

    onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(false)
    }
}

export class ConfirmationData {
    title = ''
    description = ''
    okBtn = 'Ok'
    cancelBtn = 'Cancelar'
}
