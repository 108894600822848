<header>
    <img src="./assets/img/logo_simple.png" />
    <h3>e-cclesia</h3>
    <button class="print_button" mat-raised-button (click)="print()">
        <mat-icon>print</mat-icon>Imprimir
    </button>
</header>
<div class="container ticket">
    <h2>Parroquia de la Divina Providencia</h2>
    <h6 *ngIf="data?.location">{{data?.location}}</h6>
    <div class="datos">
        <h4>
            14 Oriente 4215 Col. El Porvenir C.P. 72310 Puebla, Pue
        </h4>
        <h4>
            Tel. 222 2 35 13 64
        </h4>
    </div>
    <div class="datos">
        <h4>
            {{data?.date | date:'dd/MM/YYYY'}}
        </h4>
        <h4>
            {{data?.date | date:'hh:mm a'}}
        </h4>
    </div>
    <h5
                >Ticket No.&nbsp;<span>{{ data?.ticketNo?.padStart(5, '0') }}</span></h5
            >
    <div class="nombre">
        <label>Recibí del Sr.(a):</label>
        <p>{{ data?.payee }}</p>
    </div>
    <div class="nombre">
        <label>Concepto:</label>
        <p>{{ data?.category }}</p>
    </div>
    <div class="nombre">
        <label>Notas:</label>
        <p>{{ data?.notes }}</p>
    </div>
    <div class="nombre">
        <label>Cantidad:</label>
        <p>{{ data?.amount | currency }}</p>
    </div>

    <hr>

    <div class="nombre">
        <label>Total:</label>
        <p>{{ data?.amount | currency }}</p>
    </div>
    <p class="info">
        COMPROBANTE SIN EFECTOS FISCALES
NO SE ACEPTAN CAMBIOS NI DEVOLUCIONES<br>
<b>GRACIAS POR SU VISITA</b>
    </p>
</div>

<div class="container ticket">
    <h2>Parroquia de la Divina Providencia</h2>
    <h6 *ngIf="data?.location">{{data?.location}}</h6>
    <div class="datos">
        <h4>
            14 Oriente 4215 Col. El Porvenir C.P. 72310 Puebla, Pue
        </h4>
        <h4>
            Tel. 222 2 35 13 64
        </h4>
    </div>
    <div class="datos">
        <h4>
            {{data?.date | date:'dd/MM/YYYY'}}
        </h4>
        <h4>
            {{data?.date | date:'hh:mm a'}}
        </h4>
    </div>
    <h5
                >Ticket No.&nbsp;<span>{{ data?.ticketNo?.padStart(5, '0') }}</span></h5
            >
    <div class="nombre">
        <label>Recibí del Sr.(a):</label>
        <p>{{ data?.payee }}</p>
    </div>
    <div class="nombre">
        <label>Concepto:</label>
        <p>{{ data?.category }}</p>
    </div>
    <div class="nombre">
        <label>Notas:</label>
        <p>{{ data?.notes }}</p>
    </div>
    <div class="nombre">
        <label>Cantidad:</label>
        <p>{{ data?.amount | currency }}</p>
    </div>

    <hr>

    <div class="nombre">
        <label>Total:</label>
        <p>{{ data?.amount | currency }}</p>
    </div>
    <p class="info">
        COMPROBANTE SIN EFECTOS FISCALES
NO SE ACEPTAN CAMBIOS NI DEVOLUCIONES<br>
<b>GRACIAS POR SU VISITA</b>
    </p>
</div>