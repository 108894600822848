<div class="container">
    <h3 mat-dialog-title>{{ title }}</h3>

    <form
        class="mat-dialog-content"
        (submit)="(submit)"
        #formControl="ngForm"
        [formGroup]="formGroup"
    >
        <!--Contains mat-hint for characters count and has maxLengt set-->
        <div class="form" [formGroup]="formGroup">
            <mat-form-field color="accent">
                <input
                    matInput
                    #inputstate
                    class="form-control"
                    placeholder="Descripcion"
                    formControlName="notes"
                />
                <mat-error *ngIf="formGroup.invalid">{{
                    getErrorMessage()
                }}</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
                <input
                    matInput
                    #inputstate
                    class="form-control"
                    type="number"
                    placeholder="Numero de recibo"
                    formControlName="ticketNo"
                />
                <mat-error *ngIf="formGroup.invalid">{{
                    getErrorMessage()
                }}</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
                <input
                    matInput
                    #inputstate
                    class="form-control"
                    placeholder="Solicitante"
                    formControlName="payee"
                />
                <mat-error *ngIf="formGroup.invalid">{{
                    getErrorMessage()
                }}</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
                <input
                    matInput
                    #inputstate
                    class="form-control"
                    placeholder="Cantidad"
                    formControlName="amount"
                    type="number"
                />
                <mat-error *ngIf="formGroup.invalid">{{
                    getErrorMessage()
                }}</mat-error>
            </mat-form-field>

            <div class="field-container">
                <mat-form-field color="accent">
                    <mat-label>Categoria</mat-label>
                    <mat-select
                        class="form-control"
                        formControlName="categoryId"
                        (selectionChange)="categorySelected($event.value)"
                    >
                        <mat-option
                            *ngFor="let category of data.categories"
                            [value]="category.categoryId"
                        >
                            {{ category.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field color="accent">
                    <mat-label>Subcategoria</mat-label>
                    <mat-select
                        class="form-control"
                        formControlName="subcategoryId"
                    >
                        <mat-option
                            *ngFor="let subcategory of subcategories"
                            [value]="subcategory.subcategoryId"
                        >
                            {{ subcategory.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
            </div>
        </div>
        <mat-form-field color="accent">
            <mat-label>Ubicacion</mat-label>
            <mat-select
                class="form-control"
                formControlName="locationId"
            >
                <mat-option
                    *ngFor="let location of locations"
                    [value]="location.locationId"
                >
                    {{ location.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div mat-dialog-actions>
            <button
                mat-button
                [disabled]="!formGroup.valid"
                [mat-dialog-close]="1"
                (click)="confirmAdd()"
            >
                Guardar
            </button>
            <button
                type="button"
                mat-button
                (click)="onNoClick()"
                tabindex="-1"
            >
                Cancelar
            </button>
        </div>
    </form>
</div>
