import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { AddDialogComponent } from '../../components/dialogs/add-dialog/add-dialog.component'

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
    displayedColumns = ['id', 'name', 'actions']
    dataSource = new MatTableDataSource()
    private _filter: string = ''
    get filter() {
        return this._filter
    }
    set filter(filter: string) {
        this._filter = filter
    }
    range = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    })
    constructor(
        private categoriesService: CategoriesService,
        public dialog: MatDialog
    ) {}
    customFilter() {
        this.dataSource.filter = '' + Math.random()
    }
    resetRange() {
        this.range?.get('start')?.patchValue('')
        this.range?.get('end')?.patchValue('')
        this.customFilter()
    }
}
