import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { COOKIE_ROLE } from 'src/app/core/const/constants'
import { SideNavService } from 'src/app/core/services/util/side-nav.service'

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class SideNavComponent implements OnInit {
    private _isAdmin = false
    get isAdmin() {
        return this._isAdmin
    }
    constructor(
        public sideNavService: SideNavService,
        private route: ActivatedRoute,
        public router: Router,
        private cookieService: CookieService
    ) {}

    ngOnInit(): void {
        let routeArray = this.router.url.split('/')
        let role = this.cookieService.get(COOKIE_ROLE)
        this._isAdmin = role === 'Administrator'
    }
    navigateTo(route: string) {
        this.router.navigate([route])
    }
}
